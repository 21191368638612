@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
:root {
  --primary-color: #ffb800;
  --background-color: #f9f9f9;
}

body,
html {
  font-family: 'Quicksand', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9f9f9;
  background-color: var(--background-color);
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

strong {
  font-weight: 600;
}

.container {
  padding: 0 1rem;
}

.main-container {
  width: 1440px;
}

.title {
  color: #ffb800;
  color: var(--primary-color);
  font-weight: 500;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.btn {
  display: inline-block;
  border: none;
  border-radius: 0;
  background: #ffb800;
  background: var(--primary-color);
  color: black;
  padding: 7px 20px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
}

.btn:hover {
  background: #666;
  color: white;
}

.wrap {
  flex-wrap: wrap;
}

.flex-container {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  justify-content: space-around;
}

#navbar {
  color: black;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0);
}

.navbar-toggler {
  z-index: 301;
}

.nav-item {
  border: 2px solid transparent;
  border-radius: 2px;
  transition: all 0.3s;
  margin-left: 2rem;
}

.nav-item:hover {
  border-color: black;
}

#main-section .jumbotron {
  background-color: inherit;
  padding-left: 0;
  padding-top: 126px;
  max-width: 684px;
  height: 385px;
  position: relative;
}

#main-image-container {
  width: 40%;
  height: 100%;
  position: absolute;
  right: 0px;
  top: -160px;
}

#main-image {
  width: 100%;
  height: 145%;
  background-image: url(/static/media/portuguese_food.cbadda75.png);
  background-size: cover;
  padding: 0; /* reset */
  padding-bottom: 100%;
}

#restaurant-name {
  font-size: 80px;
  line-height: 100px;
}

#restaurant-slogan {
  font-weight: normal;
  font-size: 42px;
  line-height: 52px;
  max-width: 416px;
}

#page-content {
  margin-top: 515px;
  padding: 0 1rem;
}

.section-container {
  margin-bottom: 80px;
}

.section-container .title {
  font-size: 48px;
  text-align: center;
}

#specialities {
  margin-top: 74px;
}

.speciality {
  max-width: 260px;
  margin-bottom: 5rem;
}

.speciality-image {
  width: 260px;
}

.speciality-name {
  font-weight: 500;
  font-size: 28px;
  line-height: 35px;
  margin-top: 18px;
}

.speciality-description {
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  margin-top: 12px;
  text-align: center;
}

.speciality-price {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  margin-top: 35px;
}

#menu-sections {
  border-bottom: 1px solid #c2c2c2;
  position: relative;
}

#chef-image {
  position: absolute;
  right: 0;
  bottom: 0;
}

.menu-section {
  padding-bottom: 0.2rem;
  list-style: none;
  margin: 0 30px;
  cursor: pointer;
  border-bottom: 4px solid #f9f9f9;
  border-bottom: 4px solid var(--background-color);
  transition: all 0.3s;
}

.menu-section:hover,
.selected {
  border-bottom: 4px solid #ffb800;
  border-bottom: 4px solid var(--primary-color);
}

.menu-item-price {
  font-weight: 300;
  line-height: 30px;
}

#desserts {
  display: flex;
  flex-wrap: wrap;
}

.dessert-container {
  flex: 0 1 50%;
}

.dessert {
  margin: 2rem 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #bebebe;
}

#main-dishes-note {
  font-weight: normal;
  font-size: 18px;
}

#about-section {
  margin-top: 80px;
}

.about-image {
  width: 360px;
  height: 360px;
  margin-right: 2rem;
}

#about-text p {
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
}

#map-container {
  overflow: hidden;
}

.mapContainer {
  width: 500px;
  height: 500px;
}

#contacts-section {
  margin-top: 80px;
}

#restaurant-info {
  margin-top: 54px;
  margin-left: 20px;
}

#restaurant-info div {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 37px;
  color: black;
}

#restaurant-info svg {
  margin-right: 35px;
  color: #747474;
}

#schedule {
  position: relative;
}

#schedule-exception {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: absolute;
  top: 35px;
  left: 60px;
}

#footer {
  height: 136px;
  background-color: #ffb800;
  background-color: var(--primary-color);
  position: relative;
}

#author {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  color: white;
}

#author-name {
  border-bottom: 2px solid white;
  transition: all 0.3s;
}

#author-name:hover {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid aqua;
}

#footer {
  padding: 1.6rem;
}

.icon {
  margin: 0.7rem 0.5rem;
}

#icons a:first-of-type {
  position: relative;
  top: 2px;
}

#mail,
#name {
  width: 100%;
}

#message {
  width: 100%;
  height: 400px;
  resize: none;
}

@media (max-width: 995px) {
  #chef-image {
    display: none;
  }

  #about-section {
    justify-content: center;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  .about-image {
    margin: 2rem;
  }

  #about-text p {
    flex: 0 1 100%;
  }
}

.main-dish-section {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  border-bottom: 2px solid #cecece;
  color: #cecece;
  margin-top: 2rem;
}

@media (max-width: 1200px) {
  #contacts-section {
    flex-wrap: wrap;
  }

  #email-form-container {
    margin-top: 4rem;
  }
}

@media (max-width: 800px) {
  .dessert-container {
    flex: 0 1 100%;
  }
}

@media (max-width: 768px) {
  #main-image-container {
    display: none;
  }

  #page-content {
    margin-top: 17.5rem;
  }

  #nav-items {
    margin-top: 2rem;
  }

  #nav-items .nav-item {
    text-align: center;
    margin-top: 1rem;
    margin-left: 0;
  }

  .nav-item:hover {
    border-color: #f9f9f9;
    border-color: var(--background-color);
    border-bottom: 2px solid black;
  }
}

@media (max-width: 540px) {
  #menu-sections {
    margin: 0 1rem;
  }

  .menu-section {
    font-size: 18px;
  }

  .btn-group {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #restaurant-name {
    max-width: 375px;
    font-size: 58px;
    line-height: 80px;
  }

  #restaurant-slogan {
    max-width: 375px;
    font-size: 36px;
  }

  .menu-section {
    font-size: 14px;
  }

  #footer {
    height: 156px;
  }
}

@media (max-width: 400px) {
  .menu-section {
    font-size: 12px;
    margin: 0 10px;
  }
}

